import './App.css';

function App() {
  return (
    <div className="App">
      <span>Hello World!</span>
    </div>
  );
}

export default App;
